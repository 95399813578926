// Here you can add other styles
@font-face {
  font-family: 'twire';
  src: url('/assets/webfonts/SinkinSans-900XBlack.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'agency-fb';
  src: url('/assets/webfonts/agency-fb.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}

// Use this if you want to map heatmap export
// .app-body {
//   width: 8192px;
//   height: 8192px;
// }

.discord-button .nav-link.active {
  background-color: $discord !important;

  .nav-icon {
    color: $secondary !important;
  }
}

.twitter-button .nav-link.active {
  background-color: $twitter !important;

  .nav-icon {
    color: $secondary !important;
  }
}

.bug-button .nav-link.active {
  background-color: $warning !important;

  .nav-icon {
    color: $secondary !important;
  }
}

.production-button .nav-link.active {
  background-color: $primary !important;

  .nav-icon {
    color: $secondary !important;
  }
}

.app-loading {
  background-color: $tertiary;
  color: $secondary;
  height: 100vh;
}

// Ads privacy design
#cmpbox {
  background: $tertiary !important;
  border-color: $primary !important;

  div {
    color: $secondary !important;
  }

  div.cmplogo .cmpdl {
    display: none !important;
  }

  path.cmpsvggreenfill {
    fill: $primary !important;
  }

  path.cmpsvgredfill {
    fill: $danger !important;
  }

  .cmpboxbtn.cmpboxbtncustom, .cmpboxbtn.cmpboxbtnsave, .cmpboxbtn.cmpboxbtnyes {
    background: $primary !important;
  }

  a.cmpmorelink {
    color: $primary !important;
    text-decoration: underline;
  }
}