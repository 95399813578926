// Elementary colors - use those prior to color variables if possible
$primary: #F2AA04;
$primaryLight: #FEC33E;
$primaryShadow: rgba(239, 168, 40, 0.4);
$primaryGlow: #FEC33E;
$secondary: #FFFFFF;
$tertiary: #1C1D22;
$tertiaryDark: #161719;
$tertiaryTransparent: #1C1D22D8;
$warning: #F28204;
$danger: #F4512D;
$success: #209191;
$info: #989AA2;
$infoDark: #5B6073;
$infoDarkTransparent: #5B6073D8;
$transparent: #00000000;
$discord: rgb(114, 137, 218);
$twitter: rgb(29, 161, 242);
$gold: #FEE101;
$silver: #A7A7AD;
$bronze: #A77044;
// Zone colors
$safezone: $secondary;
$redzone: rgba(255, 0, 0, 0.4);
$bluezone: #4259FA;
$bluezoneTransparent: rgba(66, 89, 250, 0.4);
$blackzone: rgba(0, 0, 0, 0.4);
$planePath: rgba(255, 0, 0, 0.8);
// Player status colors
$alive: $secondary;
$knocked: $danger;
$dead: $tertiaryDark;
